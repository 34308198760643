const USER = {
  SIGN_IN: {
    FETCH: "SIGN_IN_FETCH",
    SUCCESS: "SIGN_IN_SUCCESS",
    ERROR: "SIGN_IN_ERROR",
  },
  CLEAR_ADV: {
    SUCCESS: "CLEAR_ADV_SUCCESS",
  },
  SIGN_OUT: {
    FETCH: "SIGN_OUT_FETCH",
    SUCCESS: "SIGN_OUT_SUCCESS",
  },
  SIGN_UP: {
    FETCH: "SIGN_UP_FETCH",
    SUCCESS: "SIGN_UP_SUCCESS",
    ERROR: "SIGN_UP_ERROR",
  },
  INIT_ADMIN_USER: {
    FETCH: "INIT_ADMIN_USER_FETCH",
    SUCCESS: "INIT_ADMIN_USER_SUCCESS",
    ERROR: "INIT_ADMIN_USER_ERROR",
  },
  GET_PROFILE: {
    FETCH: "GET_PROFILE_FETCH",
    SUCCESS: "GET_PROFILE_SUCCESS",
    ERROR: "GET_PROFILE_ERROR",
  },
  SHARING_PROFILE: {
    FETCH: "SHARING_PROFILE_FETCH",
    SUCCESS: "SHARING_PROFILE_SUCCESS",
    ERROR: "SHARING_PROFILE_ERROR",
  },
};

export default USER;
