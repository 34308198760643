const user = {
  fetching: false,
  role: {},
  email: "",
  firstName: "",
  lastName: "",
  isLoggedIn: false,
  isGetUserFetched: false,
  shouldShowAdv: false,
};

export default user;
