const SIGN_UP_DETAILS = {
  INTERESTS: {
    SET_INTEREST: "SET_INTEREST",
  },
  PASSWORD: {
    CREATE_PASSWORD: "CREATE_PASSWORD",
  },
  DETAILS: {
    SET_USER_DETAILS: "SET_USER_DETAILS",
    SET_COMPANY_DETAILS: "SET_COMPANY_DETAILS",
    SET_ACCOUNT_DETAILS_TAB: "SET_ACCOUNT_DETAILS_TAB",
  },
};

export default SIGN_UP_DETAILS;
