export default [
  ".jpeg",
  ".jpg",
  ".png",
  ".gif",
  ".bmp",
  ".heic",
  ".heif",
  ".mp4",
  ".webm",
  ".m4v",
  ".mov",
  ".qt",
];
